<template>
  <div class="all-course fadeIn">
    <div class="title-box">
      <div class="main-title">元习</div>
      <div class="sub-title">学习如何学习</div>
      <a-input-search class="input-search" v-model:value="keyword" placeholder="请输入教材名称搜索" @search="onSearch" />
    </div>
    <div class="course">
      <div class="catalog-box">
        <div class="catalog">
          <div class="catalog-item" :class="{ selected: catActive == i, noicon: !catalog.icon }"
            v-for="(catalog, i) of catalogList" @click="chooseCatalog(i)">
            <i class="catalog-icon" :style="{ backgroundImage: 'url(' + catalog.icon + ')' }" v-if="catalog.icon"></i>
            <span class="catalog-title">{{ catalog.name }}</span>
          </div>
        </div>
      </div>
      <div class="title-text">{{ catalogList[catActive]?.name }}</div>

      <div class="course-box">
        <div class="course-item-box" :class="{ hide: i > 9 }" v-for="(book, i) of storeBooks"
          @click="routerCourse(book)">
          <div class="course-item" :style="{ backgroundImage: 'url(' + book.extension_info?.cover + '!260x390)' }">
            <div class="course-top" @click.stop>
              <a-tooltip title="详情">
                <div class="svg-box info" @click="routerPath(`/course-info/${book.pk_store_book_id}`)">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-info"></use>
                  </svg>
                </div>
              </a-tooltip>
            </div>
          </div>
          <div class="course-name">{{ book.name }}</div>
        </div>
        <emptyBox warning="Sorry,暂无此类教材" v-if="storeBooks.length == 0"></emptyBox>
      </div>

    </div>
    <foot></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import foot from "@/component/public/foot/foot.vue";
import router from '@/router';
import emptyBox from "@/component/public/empty-box/empty-box.vue";

export default {
  name: 'home',
  components: {
    foot,
    emptyBox
  },
  data() {
    return {
      catalogList: [],
      allStoreBooks: [],
      storeBooks: [],

      catActive: 0,
      keyword: '',
      userInfo: null,

      loadding: true,
      phoneMode: true
    }
  },
  mounted() {
    this.$store.commit('showReturn', true);
    $('#content').animate({ scrollTop: 0 }, 300);
    let { keyword } = this.$route.query;
    if (keyword) this.keyword = keyword;

    this.setPageTemplate();
  },
  methods: {
    //设置pageTemplate
    setPageTemplate() {
      const { userInfo, storeBooks, currentStore, phoneMode, vipInfo } = this.$store.state;
      this.userInfo = userInfo;
      this.allStoreBooks = storeBooks;
      this.phoneMode = phoneMode;

      const typeGroups = currentStore.typeGroups || [];

      let catalogList = [];
      if (typeGroups?.length > 0 && typeGroups[0]?.list.length > 0) {
        catalogList = [{ name: "全部", id: "" }, ...typeGroups[0].list];
      }
      this.catalogList = catalogList;

      let catActive = 0;
      const { type } = this.$route.params; // 获取当前路由参数
      catalogList.forEach((item, i) => { if (type && type == item.type) catActive = i });

      catalogList.forEach((item, i) => {
        if (type && type == item.type) catActive = i;
      });

      this.chooseCatalog(catActive);
    },
    //选择分类
    chooseCatalog(i) {
      this.catActive = i;
      this.onSearch();
    },
    //关键词搜索
    onSearch() {
      const allStoreBooks = this.allStoreBooks;
      let storeBooks = [];

      const catalog = this.catalogList[this.catActive];
      if (catalog) {
        storeBooks = allStoreBooks.filter(item => item.types.indexOf(catalog.id) > -1);
        if (!catalog.id) storeBooks = allStoreBooks;
      }
      else {
        storeBooks = JSON.parse(JSON.stringify(allStoreBooks));
      }

      this.storeBooks = storeBooks.filter(item => item.name.indexOf(this.keyword) > -1);
      this.loadding = false;
    },
    //跳转
    chooseCourse(item) {
      $('#content').css('overflow-y', 'auto')
      $('#content').css('padding-right', '0px')
      router.push(`/course-info/${item.pk_store_book_id}`);
    },
    //跳转
    routerPath(url) {
      this.$router.push(url);
    },
    //跳转
    routerCourse(item) {
      if (item.isPaid) {
        this.study(item);
      }
      else {
        $("#content").css("overflow-y", "auto");
        $("#content").css("padding-right", "0px");
        router.push(`/course-info/${item.pk_store_book_id}`);
      }
    },
    //学习
    study(item) {
      const { pk_store_book_id, system_id, single_url } = item;
      let url = `/bookReader/${pk_store_book_id}`;
      if (single_url) {
        url = `${single_url}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`;
      }
      if (this.$store.state.isElectron) {
        window.open(`https://yuanxi.online/bookReader/${pk_store_book_id}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`);
      } else {
        window.open(url)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./all-course.scss";
</style>
