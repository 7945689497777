<template>
  <div class="mycourse fadeIn">
    <i class="lucidscreen"></i>
    <div class="course-box">
      <div class="course-item" :style="{ backgroundImage: 'url(' + book.extension_info.cover + ')' }"
        v-for="book of storeBooks" :key="book.fk_class_id" @click="chooseCourse(book)">
        <div class="course-top">
          <a-tooltip title="下载" v-if="$store.state.isElectron && !book.single_url">
            <div class="svg-box info" @click.stop="donwload(book)">
              <cloud-download-outlined />
            </div>
          </a-tooltip>
          <a-tooltip title="阅读数据" v-if="!book.single_url">
            <div class="svg-box info" @click.stop="portraitsBook = book">
              <svg class="icon-svg">
                <use xlink:href="#icon-statistics"></use>
              </svg>
            </div>
          </a-tooltip>
          <a-tooltip title="详情">
            <div class="svg-box info" @click.stop="routerPath(`/course-info/${book.pk_store_book_id}?from=mycourse`)">
              <svg class="icon-svg">
                <use xlink:href="#icon-info"></use>
              </svg>
            </div>
          </a-tooltip>
        </div>
        <div class="course-bottom">
          <div class="join-class-box" v-if="!book.single_url">
            <div class="join-class" v-if="!book.fk_book_class_id">
              <a-tooltip title="加入教材班">
                <div class="svg-box" @click.stop="openJoinClass(book)">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-join-class"></use>
                  </svg>
                </div>
              </a-tooltip>
            </div>
            <div class="join-class joined" v-else>
              <a-tooltip title="已加入教材班">
                <div class="svg-box">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-student-count"></use>
                  </svg>
                </div>
              </a-tooltip>
            </div>
          </div>
          <div class="course-status" :class="{ finished: book.isFinished, hide: book.joining }">
            <div v-if="book.isFinished">
              <svg class="svg-icon">
                <use xlink:href="#icon-duihao" />
              </svg>
              <span>已完成</span>
            </div>
            <div v-else>
              <svg class="svg-icon">
                <use xlink:href="#icon-time" />
              </svg>
              <span>进行中</span>
            </div>
          </div>
        </div>
      </div>

      <div class="empty-box" v-if="storeBooks.length == 0">
        <img :src="empty_image" alt />
        <div>暂无购买教材，请前往教材商店</div>
        <a-button type="primary" size="large" class="lp-button" @click="routerPath('/home')">教材商店</a-button>
      </div>
    </div>
    <foot></foot>

    <JoinClass :joinBook="joinBook" @closebox="joinBook = null" @joinSuccess="joinSuccess" v-if="joinBook">
    </JoinClass>

    <!-- 学生画像 -->
    <div class="new-popup-box portraits-box fadeIn" v-if="portraitsBook">
      <div class="hanlder-box">
        <svg class="icon-svg popup-close" @click="portraitsBook = null">
          <use xlink:href="#icon-guanbi" />
        </svg>
      </div>
      <div class="box-content">
        <PortraitsContent :currentBook="portraitsBook"></PortraitsContent>
      </div>
    </div>

  </div>
</template>

<script>
import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import JoinClass from "@/component/mycourse/JoinClass.vue";

import PortraitsContent from "@/component/personal/portraits-info/portraits-content.vue";
import { message } from 'ant-design-vue';
import { PASSPORT_CLIENT_SERVER } from '@/config/url';
import { CloudDownloadOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    foot,
    navroot,
    JoinClass,
    CloudDownloadOutlined,
    PortraitsContent
  },
  data() {
    return {
      storeBooks: [],

      empty_image: "https://img.lessonplan.cn/IMG/yuanxi/images/empty.png",
      backgroundImage: "https://img.lessonplan.cn/IMG/yuanxi/images/my-book/background.jpg",
      keyword: "",

      userInfo: null,
      userGuid: "",

      finishStatus: null,

      loading: true,
      updateDgUser: false,
      phoneMode: false,
      config: null,

      isSearching: false,
      joinBook: null,
      portraitsBook: null
    };
  },
  computed: {

  },
  mounted() {
    const { userInfo, storeBooks, phoneMode } = this.$store.state;

    if (!userInfo) {
      if (this.$store.state.isElectron) {
        this.$router.push('/login');
      } else {
        location.href = `${PASSPORT_CLIENT_SERVER}/yuanxi?redirectURI=${location.href}`;
      }
    }

    this.userInfo = userInfo;
    this.userGuid = userInfo.PK_UserGuid;

    this.phoneMode = phoneMode;
    this.$store.commit('showReturn', true);

    const newStoreBooks = storeBooks.filter((item) => item.isPaid);
    this.storeBooks = JSON.parse(JSON.stringify(newStoreBooks));

    // this.getStuRecord();
  },
  methods: {
    //查询学生所有记录
    async getStuRecord() {
      let res = await this.$api.unifydata.getStudentRecord();
      if (res.statusCode != 200) return this.$message.error(res.message);
      let commitList = res.data;

      this.storeBooks.forEach((item, i) => {
        item.isFinished = false;
        let commit = commitList.find((citem) => item.fk_class_id == citem.classId);

        if (commit) {
          let finishCode = commit.contents.length || 0;
          item.isFinished = finishCode >= item.codeCount;
        }
      })
    },
    //选择教材
    chooseCourse(item) {
      const { pk_store_book_id, system_id, single_url } = item;
      let url = `/bookReader/${pk_store_book_id}`;
      if (single_url) {
        url = `${single_url}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`;
      }
      if (this.$store.state.isElectron) {
        window.open(`https://yuanxi.online/bookReader/${pk_store_book_id}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`);
      } else {
        window.open(url)
      }
    },
    //跳转
    routerPath(url) {
      this.$router.push(url);
    },
    //加入教材班级
    openJoinClass(book) {
      this.joinBook = book;
    },
    donwload(book) {
      const loadding = message.loading(`下载${book.name}中`, 0);
      this.$api.download.downloadTextbook({
        "PK_PublishingGuid": book.fk_publishing_id,
        "PK_OutlineGuid": book.extension_info.outlineId,
        extensionInfo: book.extension_info
      }).then(res => {
        message.success(`下载${book.name}成功`);
        loadding();
      })
    },
    joinSuccess(joinClass) {
      const { fk_publishing_id, pk_book_class_id } = joinClass;
      this.storeBooks.forEach((item) => {
        if (item.fk_publishing_id == fk_publishing_id) {
          item.fk_book_class_id = pk_book_class_id;
        }
      })

      const newStoreBooks = JSON.parse(JSON.stringify(this.$store.state.storeBooks));
      newStoreBooks.forEach((item) => {
        if (item.fk_publishing_id == fk_publishing_id) {
          item.fk_book_class_id = pk_book_class_id;
        }
      })
      this.$store.commit("setStoreBooks", newStoreBooks);

      this.joinBook = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./mycourse.scss";
</style>